import { addOrRemoveFromArray } from 'helpers/arrays'
import { getCurrencyCode } from 'helpers/currency'
import {
  validateDatabaseFilters,
  mapFiltersToApiParams
} from 'helpers/database'
import { scrollToElement } from 'helpers/scroll'

import { t } from 'services/i18n'
import Api from 'services/api'
import { csvProductExport } from 'services/csv_export'
import { createProductTracks, createCategory } from 'actions/tracker'
import * as type from 'constants/action_types'
import {
  CSV_EXPORT_VARIANTS_LIMIT,
  PRODUCT_DATABASE_JUNGLE_SCOUT_PRESETS
} from 'constants/product_database/product_database'
import { STANDARD_TIERS, OVERSIZE_TIERS } from 'constants/filters'
import { ES_RESULTS_LIMIT } from 'constants/elasticsearch'
import {
  sendCtaClickEvent,
  sendSegmentSearchResultViewedEvent
} from 'helpers/segment'
import {
  PRODUCT_RESEARCH_SURVEY,
  triggerUsabillaCampaign
} from 'services/usabilla_live'
import { getCalculatedCategory } from 'helpers/categories'
import { createNotification } from './notifications'

/**
 *
 * Base Database Actions
 *
 */
export const getProducts = (skipCounter, scrapedParentAsin) => async (
  dispatch,
  getState
) => {
  const {
    categories: { categories },
    globalData: { featureAccess, appType },
    database: {
      data: { selectedCountry }
    }
  } = getState()

  const countryCategories = categories[selectedCountry] || []

  if (!scrapedParentAsin && !featureAccess.database) {
    dispatch({ type: type.RESET_DATABASE_FILTERS })
  }

  dispatch({
    type: scrapedParentAsin
      ? type.PENDING_PRODUCT_VARIANTS_SEARCH
      : type.PENDING_PRODUCT_SEARCH,
    payload: { scrapedParentAsin }
  })

  const collapseParent = !scrapedParentAsin

  const databaseState = getState().database
  const params = {
    ...databaseState,
    collapseParent,
    skipCounter,
    filters: mapFiltersToApiParams(databaseState.filters, countryCategories)
  }

  if (scrapedParentAsin) {
    params.filters = {
      ...params.filters,
      page_size: 50,
      from: 0,
      scraped_parent_asins: [scrapedParentAsin]
    }
  }

  const res = await Api.getProducts(params)

  if (res.ok) {
    const {
      data,
      filters,
      databaseAccess: access,
      remainingSearches: searches
    } = res.data

    // Iterate all products and format the category name
    data.products = data?.products?.map(product => {
      const calculatedCategory = getCalculatedCategory(
        countryCategories,
        product.subCategory || product.calculatedCategory
      )

      const result = {
        ...product,
        calculatedCategory: calculatedCategory.name
      }
      if (!calculatedCategory.supported) {
        result.unsupportedCategory = true
        delete result.estRevenue
        delete result.estimatedSales
      }

      return result
    })

    dispatch({
      type: scrapedParentAsin
        ? type.COMPLETED_PRODUCT_VARIANTS_SEARCH
        : type.COMPLETED_PRODUCT_SEARCH,
      data: { ...data, currencyCode: getCurrencyCode(data.selectedCountry) },
      scrapedParentAsin,
      filters
    })

    sendSegmentSearchResultViewedEvent('Product Database', appType)

    if (!getState().globalData.membershipInfo.hasActiveMembership) {
      dispatch({
        type: type.UPDATE_FEATURE_ACCESS,
        payload: {
          prop: 'database',
          access,
          searches
        }
      })
    }
  } else {
    dispatch({
      type: scrapedParentAsin
        ? type.FAILED_PRODUCT_VARIANTS_SEARCH
        : type.FAILED_PRODUCT_SEARCH,
      payload: { scrapedParentAsin }
    })

    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: 'Search Error'
      })
    )
  }
}

export const addToTracker = ({
  product,
  category,
  isNewCategory,
  isPrivateCategory,
  country,
  // eslint-disable-next-line no-shadow
  t
}) => async (dispatch, getState) => {
  const isParent =
    product.asin === product.scrapedParentAsin &&
    typeof product.variantAsinsCount === 'number' &&
    product.variantAsinsCount > 0

  if (isParent && !product.variantAsins) {
    await dispatch(getProducts(true, product.scrapedParentAsin))
  }

  const updatedProduct = getState().database.data.products.find(
    p => p.asin === product.asin
  )

  // TODO: This needs more decoupling.
  // If the getProducts is called, then the reducer makes each array item
  // as an object. If not, then the items are ASIN strings. Therefore, these 3
  // entities know too much about each other: function getProducts, reducer case
  // for COMPLETED_PRODUCT_VARIANTS_SEARCH, and the .map block below.
  const updatedVariants = updatedProduct?.variantAsins?.map(item => {
    if (typeof item === 'string') {
      const asin = item

      return {
        id: `${country}/${asin}`,
        country,
        asin
      }
    }

    return item
  })

  if (isParent && !updatedVariants) {
    dispatch(
      createNotification({
        level: 'error',
        title: t('product_database:BulkAddToTracker.error.title', 'Error'),
        message: t(
          'product_database:BulkAddToTracker.error.desc',
          'Could not add products to your Tracker'
        )
      })
    )

    return
  }

  const products = isParent ? updatedVariants : [product]

  if (isNewCategory) {
    await dispatch(
      createCategory({
        products,
        category,
        isPrivate: isPrivateCategory,
        country
      })
    )
  } else {
    await dispatch(createProductTracks({ products, category }))
    triggerUsabillaCampaign(PRODUCT_RESEARCH_SURVEY)
  }
}

// eslint-disable-next-line no-shadow
export const downloadProductCSV = t => async (dispatch, getState) => {
  const {
    database: {
      data: { products }
    },
    globalData: { appType }
  } = getState()

  dispatch({ type: type.PENDING_DATABASE_CSV_EXPORT })

  const productsWithVariants = []
  const parentsMap = {}

  // Don't mutate the products from store because the missing variants will be just samples
  products.forEach(product => {
    const productCopy = { ...product }

    if (productCopy.hasVariants) {
      const variantsCopy = product.variants || []

      productCopy.variants = [...variantsCopy]
      parentsMap[product.asin] = productCopy
    }

    productsWithVariants.push(productCopy)
  })

  const res = await Promise.resolve({ data: [], ok: true })

  if (res.ok) {
    res.data.forEach(variant => {
      const parent = parentsMap[variant.scrapedParentAsin]

      if (parent) {
        parent.variants.push(variant)
      }
    })
  } else {
    dispatch(
      createNotification({
        level: 'error',
        title: t('product_database:DatabaseTable.csv.exportError', 'CSV Error'),
        message: res.error
      })
    )
  }

  const productsToExport = productsWithVariants.map(product => {
    const variants = product.variants || []
    return [product, ...variants.slice(0, CSV_EXPORT_VARIANTS_LIMIT)]
  })

  csvProductExport(productsToExport.flat(), appType)

  dispatch({ type: type.COMPLETED_DATABASE_CSV_EXPORT })
}

export const setDatabasePage = (page, skipCounter = false) => async (
  dispatch,
  getState
) => {
  const perPage = parseInt(getState().database.filters.paginate.pageSize, 10)
  const startingPoint = page * perPage - perPage
  const limit = ES_RESULTS_LIMIT - perPage
  const from = startingPoint >= limit ? limit : startingPoint

  dispatch({
    type: type.SET_DATABASE_PAGE,
    from,
    page
  })

  await dispatch(getProducts(skipCounter)) // don't increment search counter on page change

  scrollToElement('table--database', {
    offset: -75
  })
}

export const sortDatabaseV2 = ({ column, direction }) => async dispatch => {
  dispatch({
    type: type.SORT_DATABASE,
    column,
    direction
  })

  await dispatch(setDatabasePage(1, true))
}

/**
 *
 * For Individual Products
 *
 */

export const refreshProductVariant = ({ asin, country, parentAsin }) => async (
  dispatch,
  getState
) => {
  const product = getState().database.data.products.find(
    p => p.asin === parentAsin && p.country === country
  )

  const variant = product.variantAsins.find(
    p => p.asin === asin && p.country === country
  )

  if (variant && variant.isLoading) {
    return
  }

  dispatch({
    type: type.PENDING_PRODUCT_VARIANT_REFRESH,
    asin,
    parentAsin
  })

  const res = await Api.refreshProduct({ asin, amz_store: country })

  if (res.ok) {
    dispatch({
      type: type.COMPLETED_PRODUCT_VARIANT_REFRESH,
      asin,
      product: res.data,
      parentAsin
    })

    dispatch(
      createNotification({
        message: res.message,
        level: 'success',
        title: 'Product Update Notice:'
      })
    )
  } else {
    dispatch({
      type: type.FAILED_PRODUCT_VARIANT_REFRESH,
      asin,
      parentAsin
    })

    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: 'Product Update Notice:'
      })
    )
  }
}

export const refreshProduct = ({
  asin,
  country,
  parentProductId = null
}) => async (dispatch, getState) => {
  const product = getState().database.data.products.find(
    p => p.asin === asin && p.country === country
  )

  if (product && product.isLoading) {
    return
  }

  dispatch({
    type: type.PENDING_PRODUCT_REFRESH,
    asin,
    parentProductId
  })

  const res = await Api.refreshProduct({ asin, amz_store: country })

  if (res.ok) {
    dispatch({
      type: type.COMPLETED_PRODUCT_REFRESH,
      asin,
      product: res.data,
      parentProductId
    })

    dispatch(
      createNotification({
        message: res.message,
        level: 'success',
        title: 'Product Update Notice:'
      })
    )
  } else {
    dispatch({
      type: type.FAILED_PRODUCT_REFRESH,
      asin,
      parentProductId
    })

    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: 'Product Update Notice:'
      })
    )
  }
}

/**
 *
 * Filtering
 *
 */
export const validateFilters = () => (dispatch, getState) => {
  const { filters } = getState().database
  const result = validateDatabaseFilters(filters)

  if (!result.valid) {
    dispatch(
      createNotification({
        message: result.message,
        level: 'error',
        title: 'Filter error:'
      })
    )
    return false
  }

  return true
}

export const selectMarketplace = code => async (dispatch, getState) => {
  const { selectedCountry } = getState().database.data

  if (selectedCountry !== code) {
    dispatch({
      type: type.SET_DATABASE_COUNTRY,
      payload: {
        country: code,
        currencyCode: getCurrencyCode(code)
      }
    })
  }
}

export const selectCategory = category => (dispatch, getState) => {
  const currentCategories = getState().database.filters.calculatedCategory
    .valuesArray
  const selectedCategories = addOrRemoveFromArray(currentCategories, category)

  dispatch({
    type: type.SET_DATABASE_SELECTED_CATEGORIES,
    categories: selectedCategories
  })
}

export const selectCategories = categories => dispatch => {
  dispatch({
    type: type.SET_DATABASE_SELECTED_CATEGORIES,
    categories
  })
}

export const selectProductTier = tier => (dispatch, getState) => {
  const currentTiers = getState().database.filters.tier.valuesArray
  const newTiers = tier === 'Oversize' ? OVERSIZE_TIERS : STANDARD_TIERS
  const selectedTiers = addOrRemoveFromArray(currentTiers, newTiers)

  dispatch({
    type: type.SET_DATABASE_FILTER,
    name: 'tier',
    property: 'valuesArray',
    value: selectedTiers
  })
}

export const selectSellerType = seller => (dispatch, getState) => {
  const currentSellerTypes = getState().database.filters.sellerType.valuesArray
  const selectedSellerTypes = addOrRemoveFromArray(currentSellerTypes, seller)

  dispatch({
    type: type.SET_DATABASE_FILTER,
    name: 'sellerType',
    property: 'valuesArray',
    value: selectedSellerTypes
  })
}

export const setDatabaseMinMaxFilter = (
  filter,
  minOrMax,
  value
) => dispatch => {
  dispatch({
    type: type.SET_DATABASE_FILTER,
    name: filter,
    property: minOrMax,
    value
  })
}

export const setDateFirstAvailableRange = ({
  startDate,
  endDate,
  dateRangeType
}) => dispatch => {
  dispatch({
    type: type.SET_DATE_FIRST_AVAILABLE_RANGE,
    payload: {
      startDate,
      endDate,
      dateRangeType
    }
  })
}

export const setDatabaseKeyword = value => dispatch => {
  dispatch({
    type: type.SET_DATABASE_FILTER,
    name: 'includeKeywords',
    property: 'searchTerm',
    value
  })
}

export const setDatabaseExcludeKeyword = value => dispatch => {
  dispatch({
    type: type.SET_DATABASE_FILTER,
    name: 'excludeKeywords',
    property: 'searchTerm',
    value
  })
}

export const setDatabaseProductAvailability = value => dispatch => {
  dispatch({
    type: type.SET_DATABASE_FILTER,
    name: 'isUnavailable',
    property: 'valuesArray',
    value
  })
}

export const setDatabasePageSize = value => dispatch => {
  dispatch({
    type: type.SET_DATABASE_FILTER,
    name: 'paginate',
    property: 'pageSize',
    value
  })
}

export const setDatabasePerPage = value => async dispatch => {
  await dispatch(setDatabasePageSize(value))
  await dispatch(getProducts(true))
}

export const editDatabasePage = page => async dispatch => {
  dispatch({
    type: type.EDIT_DATABASE_PAGE,
    page
  })
}

export const resetDatabaseFilters = () => (dispatch, getState) => {
  dispatch({ type: type.RESET_DATABASE_FILTERS })
  const {
    globalData: { appType }
  } = getState()
  sendCtaClickEvent({
    destination: 'same page',
    text: 'Reset Filters',
    appType,
    location: 'Product Database'
  })
}

export const toggleExcludeTopBrands = () => dispatch => {
  dispatch({
    type: type.TOGGLE_EXCLUDE_TOP_BRANDS
  })
}

/**
 *
 * Presets
 *
 */
export const toggleDatabaseSavePreset = show => dispatch => {
  dispatch({
    type: type.TOGGLE_DATABASE_SAVE_PRESET,
    show
  })
}

export const toggleDatabaseLoadPreset = show => dispatch => {
  dispatch({
    type: type.TOGGLE_DATABASE_LOAD_PRESET,
    show
  })
}

export const toggleDatabaseDeletePreset = show => dispatch => {
  dispatch({
    type: type.TOGGLE_DATABASE_DELETE_PRESET,
    show
  })
}

export const getDatabasePresets = () => async dispatch => {
  const res = await Api.getDatabasePresets()

  if (res.ok) {
    const { data } = res

    dispatch({
      type: type.SET_DATABASE_PRESETS,
      userPresets: data.userPresets,
      jsPresets: data.jsPresets?.map(preset => ({
        ...preset,
        ...(PRODUCT_DATABASE_JUNGLE_SCOUT_PRESETS[preset.name] || {})
      }))
    })
  } else {
    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: 'Preset Load Alert!'
      })
    )
  }

  await dispatch(toggleDatabaseLoadPreset(true))
}

export const updateDatabasePresetName = value => dispatch => {
  dispatch({
    type: type.UPDATE_DATABASE_PRESET_NAME,
    value
  })
}

export const toggleMultiuserSharing = value => dispatch => {
  dispatch({
    type: type.TOGGLE_MULTIUSER_SHARING,
    is_private: value
  })
}

export const selectDatabasePreset = value => dispatch => {
  dispatch({
    type: type.SELECT_DATABASE_PRESET,
    value
  })
}

export const saveDatabasePreset = () => async (dispatch, getState) => {
  dispatch({ type: type.PENDING_DATABASE_PRESET, isLoading: true })

  const res = await Api.saveDatabasePreset(getState().database)

  if (res.ok) {
    dispatch(toggleDatabaseSavePreset(false))

    dispatch(
      createNotification({
        message: res.message,
        level: 'success',
        title: 'Database Alert!'
      })
    )
  } else {
    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: 'Database Alert!'
      })
    )
  }

  dispatch({ type: type.PENDING_DATABASE_PRESET, isLoading: false })
}

export const loadDatabasePreset = () => async (dispatch, getState) => {
  const {
    database: {
      presets: { selectedPreset }
    },
    globalData: { appType }
  } = getState()

  dispatch({ type: type.PENDING_DATABASE_PRESET, isLoading: true })

  const res = await Api.loadDatabasePreset(selectedPreset)

  if (res.ok) {
    dispatch({ type: type.LOAD_DATABASE_PRESET, filters: res.data })

    sendCtaClickEvent({
      destination: 'modal',
      text: 'Load Filter Set',
      appType,
      location: 'Product Database'
    })

    dispatch({ type: type.TOGGLE_DATABASE_LOAD_PRESET, show: false })

    dispatch(
      createNotification({
        message: t(
          'common:LoadPreset.notification.message.databaseAlert',
          'Preset Loaded: {{label}}',
          {
            label:
              typeof selectedPreset.label === 'function'
                ? selectedPreset.label(t)
                : selectedPreset.name
          }
        ),
        level: 'success',
        title: t(
          'common:LoadPreset.notification.title.databaseAlert',
          'Database Alert!'
        )
      })
    )
  } else {
    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: 'Database Alert!'
      })
    )
  }

  dispatch({ type: type.PENDING_DATABASE_PRESET, isLoading: false })
}

export const deleteDatabasePreset = () => async (dispatch, getState) => {
  const { selectedPreset } = getState().database.presets

  const res = await Api.deleteDatabasePreset(selectedPreset)

  if (res.ok) {
    dispatch({
      type: type.SET_DATABASE_PRESETS,
      userPresets: res.data.userPresets,
      jsPresets: res.data.jsPresets?.map(preset => ({
        ...preset,
        ...(PRODUCT_DATABASE_JUNGLE_SCOUT_PRESETS[preset.name] || {})
      }))
    })

    dispatch(toggleDatabaseDeletePreset(false))

    dispatch(toggleDatabaseLoadPreset(false))

    dispatch(
      createNotification({
        message: res.message,
        level: 'success',
        title: 'Database Alert!'
      })
    )
  } else {
    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: 'Database Alert!'
      })
    )
  }
}
